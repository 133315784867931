/**
 *
 * in stimulus_bootstrap.js
     * import MeaMercure from '@mea/core/webpack/mercure/mercure_controller';
     * app.register("mercure", MeaMercure)
 *
 */

import { Controller } from '@hotwired/stimulus';
import { NativeEventSource, EventSourcePolyfill } from 'event-source-polyfill';

export default class extends Controller {
    static values = {
        env: String ,
        host: String ,
        token: String ,
        topics: Array
    };

    connect() {

        this.allowReconnect = true;
        this.connectionErrorCouter = 0;

        window.addEventListener("beforeunload", ()=>{
            this.allowReconnect = false;
        });

        this.remoteConnectionInit();
    }

    isDebug(){
        return this.envValue ==='dev';
    }

    disconnect() {
        super.disconnect();
    }

    remoteConnectionInit(){

        if(!this.hostValue){
            console.error('mercure host empty');
            return;
        }
        let url = new URL(this.hostValue);
        this.topics = [];

        $.each(this.topicsValue,(k,topic)=>{
            this.topics.push(topic);
            url.searchParams.append('topic', topic);
        });

     //  const es = new EventSource(url, withCredentials: true);

        if(this.es){
            // console.log('closing old connection');
            this.es.close();
        }

        try{
            this.es = new EventSourcePolyfill(url, {
                headers: {
                    'Authorization': 'Bearer ' + this.tokenValue,
                }
            });
        }catch (e) {
            // console.log('mercure1: e',e);
        }


        this.es.addEventListener("error", (e)=>{
            // console.log('mercure: e',e);
        });

        this.es.onopen = e => {
            this.allowReconnect = true;
            this.connectionErrorCouter=0;
            if(this.isDebug())
            console.log('mercure: connected',this.topics);
        }


        this.es.onmessage = e => {
            let responseParsed = $mea.ajax.parseResponse(e.data)
            const data = JSON.parse(e.data);
            //todo:
            console.log('mercure_message: message',e, data);

        }

        this.es.onerror = event => {

            if(event.statusText ==='Unauthorized'){
                if(this.isDebug())
                console.log('mercure: Unauthorized',timeout , event, this.connectionErrorCouter, updateTime);
                $mea.messages.error('No connection to the server',0);
                return ;
            }

            this.connectionErrorCouter ++;
            let timeout;
            let updateTime;

            if(this.connectionErrorCouter < 4){
                updateTime = 1000;
            }else if(this.connectionErrorCouter < 10){
                updateTime = 2000;
            }else if(this.connectionErrorCouter > 10){
                updateTime = 20000;
                if(this.isDebug())
                $mea.messages.error('No connection to the server',0);
                this.allowReconnect = false ;
            }

            // console.log('mercure: connection lost',timeout , event, this.connectionErrorCouter, updateTime);

            if(false === this.allowReconnect){

                if(this.isDebug())
                console.log('mercure: reconnect not allowed');

                return ;

            }

            timeout = setTimeout(()=>{

                clearTimeout(timeout);

                // console.log('mercure: reconnecting');

                this.remoteConnectionInit();

            },updateTime);

        }
    }


}