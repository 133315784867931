import { Controller } from '@hotwired/stimulus';
import $ from "jquery";

export default class extends Controller {

    connect() {

        $('.criteriaBtn').click((e)=>{

            $('.criteriaBtn').removeClass('active');

            $(e.currentTarget).addClass('active');

        });


    }




}