import { Controller } from '@hotwired/stimulus';
//import Handlebars from 'handlebars/dist/cjs/handlebars'
// import KioskBoard from "kioskboard";
// import "kioskboard/dist/kioskboard-2.3.0.min.css";

// import Keyboard from 'simple-keyboard';
// import 'simple-keyboard/build/css/index.css';

export default class extends Controller {

    static values = {
        'path': String
    }
    static targets = [
        "phoneInput",
        "amountInput",
        "errorPhone",
        "errorAmount"
    ]

    connect() {

         // this.phoneInputTarget.focus();
        setTimeout(()=>{
            $('#mpesaAmount').focus();
        },1000);

        // console.log('connect',this.phoneInputTarget);
        // return ;

        // this.validate(this.phoneInputTarget);
        this.validate(this.amountInputTarget);
    }

    validate(el){
        $(el).validate();
        $(el).on('input, change',()=>{
        // $(el).on('change',()=>{
            if($(el)[0].validity.valid){
                $(el).removeClass('is-invalid').addClass('is-valid');
            }else{
                $(el).removeClass('is-valid').addClass('is-invalid');
            }
        })
    }

    send(){

        console.log({
            phoneInputTarget: $(this.phoneInputTarget)[0].validity.valid,
            amountInputTarget: $(this.amountInputTarget)[0].validity.valid,
        });

        if(
            $(this.phoneInputTarget)[0].validity.valid === false
            ||$(this.amountInputTarget)[0].validity.valid === false
        ){

            $mea.messages.show('validation.form.has.errors',5000);
            console.log('not valid');

            return ;

        }

        if(parseInt($(this.amountInputTarget).val()) < 10){
            return ;
        }

        $mea.ajax.run(this.pathValue,{
            data:{
                'action': 'payIn',
                'phone': $(this.phoneInputTarget).val(),
                'amount': $(this.amountInputTarget).val(),
            }
        });

        // $mea.ajax.disableElement(this.element);

    }

}