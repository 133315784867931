import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

    static targets = ['input', 'button'];

    connect() {

        this.boundHandleKeydown = this.handleKeydown.bind(this);
        this.inputTarget.addEventListener('keydown', this.boundHandleKeydown);

        // Set cursor style and add click listener on button
        this.buttonTarget.style.cursor = 'pointer';
        this.boundHandleClick = this.handleClick.bind(this);
        this.buttonTarget.addEventListener('click', this.boundHandleClick);

    }

    disconnect() {
        this.inputTarget.removeEventListener('keydown', this.boundHandleKeydown);
        this.buttonTarget.removeEventListener('click', this.boundHandleClick);
    }


    handleKeydown(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.search();
        }
    }

    handleClick(event) {
        event.preventDefault();
        this.search();
    }

    search() {
        const query = this.inputTarget.value;

        $mea.ajax.load(`/games?query=${encodeURIComponent(query)}`,{

        });


    }



}