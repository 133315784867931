import { Controller } from '@hotwired/stimulus';

/**
 * https://github.com/Mottie/Keyboard#themes
 */
import keyboard from 'virtual-keyboard';

//https://github.hubspot.com/odometer/
export default class extends Controller {

    connect() {

        let _this = this;

        // if($(this.element).data('autofocus')){
        //     setTimeout(()=>{
        //         // console.log('autofocus',this.element);
        //         $(_this.element).focus();
        //     },500);
        // }

        if (window.isMobile() ===false) {

            try{

                let options = {
                    usePreview: false,
                    useCombos: false,
                    autoAccept: false,
                    
                    css: {
                        container: 'container',
                        button: "btn btn-primary",
                        buttonHovered: "btn btn-secondary",
                        buttonActive: "btn btn-success",
                        "button.shift": "btn btn-warning",
                        "button.shift.down": "btn btn-danger"
                    },
                    // customLayout: customLayout,
                    display: {
                        //     enter: 'Enter',
                        //     del: '\u2326:Delete',
                        //     redo: '↻',
                        //     undo: '↺'
                    },

                };


                let type = $(this.element).data('kioskboard-type');
                switch (type) {
                    case 'keyboard':
                        options.layout = 'qwerty';
                        options.appendTo = $(this.element).closest('.row');

                    break;

                    default: //numpad

                        options.layout = 'custom';
                        options.appendTo = $(this.element).closest('div');
                        // options.appendTo = $('body');
                        options.customLayout = {
                            'normal': [
                                '7 8 9',
                                '4 5 6',
                                '1 2 3',
                                '{clear} 0 {bksp}'
                            ],
                            'shift': [
                                '7 8 9',
                                '4 5 6',
                                '1 2 3',
                                '{clear} 0 {bksp}'
                            ]
                        };
                        break;

                }

                // console.log('kioskboard connect',type,  this.element);

                $(this.element).keyboard(options);


                /**
                 * thow exception but work
                 * TypeError: Input of type "number" is not supported; use type text, search, URL, tel or password
                  */
            }catch (e) {

                console.log(e);

            }


        }



    }

}