import { Controller } from '@hotwired/stimulus';
//import Handlebars from 'handlebars/dist/cjs/handlebars'
// import KioskBoard from "kioskboard";
// import "kioskboard/dist/kioskboard-2.3.0.min.css";

// import Keyboard from 'simple-keyboard';
// import 'simple-keyboard/build/css/index.css';

export default class extends Controller {

    static values = {
        'path': String
    }
    static targets = [
        "phoneInput",
        "amountInput",
        "errorPhone",
        "allAmount",
    ]

    connect() {

        return ;
        setTimeout(()=>{
            $('#mpesaAmount').focus();
        },1000);


        this.validate(this.phoneInputTarget);

        this.maxAmount = $('#userBalance').data('value');

        // $.validator.addClassRules("maxAmount", { cRequired: true, cMax: this.maxAmount })

        $(this.allAmountTarget)
            .html(this.maxAmount)
            .click(()=>{
                $(this.amountInputTarget).val(this.maxAmount).change();
            })
        ;

        $(this.amountInputTarget)
            // .val(this.maxAmount)
            // .on('type, input, change',(e)=> {
            .on('change',(e)=> {
                let el = $(e.currentTarget);
                let current = parseInt($(e.currentTarget).val());


                if (isNaN(current) || current === 0 || current > this.maxAmount ) {
                    $(el).removeClass('is-valid').addClass('is-invalid');

                } else {
                    $(el).removeClass('is-invalid').addClass('is-valid');

                }
            })
            .change()
        ;


        // this.validate(this.amountInputTarget);



    }



    validate(el){

        // $(el).on('type, input, change',()=>{
        $(el).on('change',()=>{
            if($(el)[0].validity.valid){
                $(el).removeClass('is-invalid').addClass('is-valid');
            }else{
                $(el).removeClass('is-valid').addClass('is-invalid');
            }
        })
    }

    send(){

        console.log({
            phoneInputTarget: $(this.phoneInputTarget)[0].validity.valid,
        });

        if(
            $(this.element).find('input.is-invalid').lenght > 0
        ){

            $mea.messages.show('validation.form.has.errors',5000);
            console.log('not valid');

            return ;

        }

        $mea.ajax.run(this.pathValue,{
            data:{
                'action': 'payOut',
                'phone': $(this.phoneInputTarget).val(),
                'amount': $(this.amountInputTarget).val(),
            }
        });
        
    }

}