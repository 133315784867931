import { Controller } from '@hotwired/stimulus';

// import EditorJS from '@editorjs/editorjs';
// import Header from '@editorjs/header';
// import List from '@editorjs/list';
// import SimpleImage from '@editorjs/simple-image';
// import ImageTool from '@editorjs/image';
// import LinkTool from '@editorjs/link';
// import Table from '@editorjs/table';
// import CodeTool from '@editorjs/code';
// import RawTool from '@editorjs/raw';
// import Marker from '@editorjs/marker';
// import Underline from '@editorjs/underline';
// import ChangeCase from 'editorjs-change-case';
// import Strikethrough from 'editorjs-strikethrough';
// import Undo from 'editorjs-undo';
// import CheckList from '@editorjs/checklist';
// import VideoTool from '@weekwood/editorjs-video';

export default class extends Controller {
    static values = {
        data: String  // Zdefiniowanie wartości 'data'
    }

    connect() {

        console.log('VideoTool:', VideoTool);
        console.log('editorjs-controller.js ready');

        // Utwórz div do przechowywania Editor.js
        this.editorContainer = document.createElement('div');
        this.editorContainer.id = `${this.element.id}_editor`;
        this.element.insertAdjacentElement('afterend', this.editorContainer);

        this.initializeEditor();
    }

    initializeEditor() {
        const editorData = this.dataValue || null;

        this.editor = new EditorJS({
            holder: this.editorContainer.id,
            data: editorData ? JSON.parse(editorData) : {},  // Inicjalizacja z danymi, jeśli dostępne
            tools: {
                video: {
                    class: VideoTool,
                    config: {
                        endpoints: {
                            byFile: 'http://localhost:8008/uploadFile',
                            byUrl: 'http://localhost:8008/fetchUrl',
                        },
                        player: {
                            pip: false,       // Picture-in-Picture ustawiony na false
                            controls: true,   // Kontrolki odtwarzania na true
                            light: false      // Inna opcja, w zależności od dokumentacji
                        }
                    },
                },
                header: {
                    class: Header,
                    inlineToolbar: true
                },
                list: {
                    class: List,
                    inlineToolbar: true
                },
                checklist: {
                    class: CheckList,
                    inlineToolbar: true
                },
                image: {
                    class: ImageTool,
                    config: {
                        endpoints: {
                            byFile: 'http://localhost:8008/uploadFile', // Your backend file uploader endpoint
                            byUrl: 'http://localhost:8008/fetchUrl', // Your endpoint that provides uploading by Url
                        }
                    }
                },
                // simpleImage: {
                //     class: SimpleImage,
                //     inlineToolbar: true
                // },
                // linkTool: {
                //     class: LinkTool,
                //     config: {
                //         endpoint: 'https://noembed.com/embed'  // Nowy serwis zamiast Microlink
                //     }
                // },
                table: {
                    class: Table,
                    inlineToolbar: true
                },
                code: CodeTool,
                raw: RawTool,
                marker: Marker,
                underline: Underline,
                changeCase: {
                    class: ChangeCase,
                    config: {
                        shortcut: 'CMD+SHIFT+U'
                    }
                },
                strikethrough: Strikethrough,
                undo: Undo,
            },
            onReady: () => {
                // if (!editorData) {
                //     // Dodaj pusty blok tylko jeśli nie ma danych
                //     this.editor.blocks.insert('paragraph', {
                //         text: ''
                //     });
                // }
            },
            onChange: () => {
                this.editor.save().then((outputData) => {
                    this.element.value = JSON.stringify(outputData);
                }).catch((error) => {
                    console.error('Saving failed: ', error);
                });
            }
        });
    }

    disconnect() {
        if (this.editor) {
            this.editor.destroy();
            this.editor = null;
        }
    }
}
