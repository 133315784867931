/**
 * from /assets/front/app.js
 *
 import './../../../vendor/Mea/ContactBundle/Resources/webpack/mea.like';
 $mea.like.register();
 */

$mea.like={

    config:{
      like_class: 'liked',
      url: ''
    },

    register: function (){

        this.config.url = $('[data-mea-like-url]').data('mea-like-url');
        if(!this.config.url){
            console.log('meaLike URL empty');
        }

        $mea.types.add('like',(el)=>{

            $(el).click((e)=>{

                if($(el).hasClass(this.config.like_class)){
                    this.remove(el);
                }else{
                    this.add(el);
                }

            });
        });
        $mea.types.add('like_manager',(el)=>{
           this.initManager(el);
        });

        $(window).on($mea.ajax.events.afterContentChanged,(e)=>{

            let elements = {};
            let i = 0;

            $(e.selector).find('[data-mea-type="like"]').each((k,v)=>{
                let $el = $(v);
                if($el.data('like-checked')===1)
                    return;

                let type = $el.data('like-type');
                if(!elements[type]){
                    elements[type] = '';
                }
                elements[type] += $el.data('like-id')+',';
                i++;
            });


            if(i===0) return ;

            $.ajax({
                dataType: "json",
                url: this.config.url,
                data: {
                    elements: elements,
                    action: 'load'
                },
                success: (r)=>{
                    if(r.like){
                        this.restore(r.like);
                    }
                }
            });


        });

        $( window ).on('load', () => {

            let elements = {};
            let i = 0;
            $('[data-mea-type="like"]').each((k,v)=>{
                let $el = $(v);
                $el.data('like-checked',1);
                let type = $el.data('like-type');
                if(!elements[type]){
                    elements[type] = '';
                }
                elements[type] += $el.data('like-id')+',';
                i++;
            });

            if(i===0) return ;

            $.ajax({
                dataType: "json",
                url: this.config.url,
                data: {
                    elements: elements,
                    action: 'load'
                },
                success: (r)=>{
                   if(r.like){
                       this.restore(r.like);
                   }
                }
            });

        });

    },

    initManager(el){

        $(el).find('[data-mea-selector="like_row"]').each((k,item)=>{

            $(item).find('.like_note').on('input',((e)=>{

                $(e.currentTarget).closest('.input-group').find('button').addClass('btn-outline-danger');

            }));

            $(item).find('.like_note_save').on('click',((e)=>{

                let content = $(e.currentTarget).closest('.input-group').find('.like_note').val();

                $(e.currentTarget)
                    .removeClass('btn-outline-danger')
                    .addClass('btn-outline-secondary')
                    .addClass('disabled');

                $.ajax({
                    dataType: "json",
                    url: this.config.url,
                    data: {
                        id: $(item).data('like-el-id'),
                        note: content,
                        action: 'note'
                    },
                    success: (r)=>{
                        if(r.status !=='ok'){

                            $mea.messages.error();

                        }else{

                            $(e.currentTarget)
                                .removeClass('disabled')
                                .removeClass('btn-outline-secondary')
                            ;


                        }
                    }
                });

            }));



            $(item).find('.like-remove').click(()=>{

                // console.log('item',item);

                $.ajax({
                    dataType: "json",
                    url: this.config.url,
                    data: {
                        id: $(item).data('like-id'),
                        type: $(item).data('like-type'),
                        action: 'remove'
                    },
                    success: (r)=>{
                        if(r.status !=='ok'){
                            console.log('like remove error',r);
                        }
                    }
                });

                $(item).remove();

            });

        });

    },

    restore: function(data){

        $(data).each((k,v)=>{

            $.each(v, (type,ids)=>{

                    $.each(ids,(k,id)=>{

                        $('[data-like-type='+type+'][data-like-id='+id+']').addClass(
                            this.config.like_class
                        )
                        //not work on kinkinplay
                        // .animate({
                        //     top: 305, opacity: '1'
                        // }, {
                        //     duration: 1000,
                        //     easing: 'easeOutBounce'
                        // });
                        ;

                    });

                });
        });

    },

    add: function (el){

        $.ajax({
            dataType: "json",
            url: this.config.url,
            data: {
                id: $(el).data('like-id'),
                type: $(el).data('like-type'),
                desc: $(el).data('like-desc'),
                action: 'add'
            },
            success: (r)=>{
                if(r.status !=='ok'){
                    $(el).removeClass(this.config.like_class);
                    $mea.messages.error();
                    console.log('like error',r);
                }
            }
        });

        $(el).addClass(this.config.like_class);

    },

    remove: function (el){
        // console.log('remove');
        $.ajax({
            dataType: "json",
            url: this.config.url,
            data: {
                id: $(el).data('like-id'),
                type: $(el).data('like-type'),
                action: 'remove'
            },
            success: (r)=>{
                console.log(r);
            }
        });

        $(el).removeClass(this.config.like_class);

    }

}