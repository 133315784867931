/**
 * @example in stimulus_bootstrap.js
import Mea_stimulus from '@mea/core/webpack/stimulus/mea_stimulus';
Mea_stimulus.registerAll(app)
 *
 */


import EditorJs from '@mea/core/webpack/editorjs/editorjs-controller';
import MeaMercure from '@mea/core/webpack/mercure/mercure_controller';
import TimeAgo from '@mea/core/webpack/timeago/timeago_controller';
import EmailWidget from '@mea/core/webpack/email-widget/email_widget_controller';
// import FilterCriteriaSaver from '@mea/core/webpack/filters-criteria-saver/filters-criteria-saver-controller';

export default class {

    static registerAll(app){

       app.register("editorjs", EditorJs)
       app.register("mercure", MeaMercure)
       app.register("timeago", TimeAgo)
       app.register("email-widget", EmailWidget)
       // app.register("filters-criteria-saver", FilterCriteriaSaver)
       // app.register("mea_list", MeaList)

        console.log('mea stimulus registered');

    }

}