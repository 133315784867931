import { Controller } from '@hotwired/stimulus';
import { NativeEventSource, EventSourcePolyfill } from 'event-source-polyfill';
import $ from "jquery";
//import { NativeEventSource, EventSourcePolyfill } from 'eventsource';

export default class extends Controller {
    static values = {
        host: String ,
        token: String ,
        topics: Array
    };

    connect() {

        this.allowReconnect = true;
        this.connectionErrorCouter = 0;

        window.addEventListener("beforeunload", ()=>{
            this.allowReconnect = false;
        });

        this.remoteConnectionInit();

        window.mercure = this;

    }

    disconnect() {
        super.disconnect();
    }

    remoteConnectionInit(){

        if(!this.hostValue){
            console.error('mercure host empty');
            return;
        }
        let url = new URL(this.hostValue);
        this.topics = [];


        if(this.topicsValue.length == 0){
            console.error('mercure topics empty');
            return;
        }

        $.each(this.topicsValue,(k,topic)=>{
            this.topics.push(topic);
            url.searchParams.append('topic', topic);
        });

        //  const es = new EventSource(url, withCredentials: true);

        if(this.es){
            console.log('closing old connection');
            this.es.close();
        }

        this.es = new EventSourcePolyfill(url, {
            headers: {
                'Authorization': 'Bearer ' + this.tokenValue,
            }
        });

        this.es.onopen = e => {
            this.allowReconnect = true;
            this.connectionErrorCouter=0;
            console.log('connected',this.topics);
        }

        this.es.onmessage = e => {
            try{
                this.onMessage(JSON.parse(e.data));
            }catch (excepption){
                console.error('message parsing error ',excepption);
            }

        }



        this.es.onerror = event => {

            if(event.statusText ==='Unauthorized'){
                $mea.messages.add('No connection to the message server. Unauthorized',0);
                return ;
            }

            this.connectionErrorCouter ++;
            let timeout;
            let updateTime;

            if(this.connectionErrorCouter < 4){
                updateTime = 1000;
            }else if(this.connectionErrorCouter < 10){
                updateTime = 2000;
            }else if(this.connectionErrorCouter > 10){
                updateTime = 20000;
                $mea.messages.add('Connection to the server was lost, reload the page',0);
                this.allowReconnect = false ;
            }

            console.log('connection lost',timeout , event, this.connectionErrorCouter, updateTime);

            if(false === this.allowReconnect){

                console.log('reconnect not allowed');

                return ;

            }

            timeout = setTimeout(()=>{

                clearTimeout(timeout);

                console.log('reconnecting');

                this.remoteConnectionInit();

            },updateTime);

        }
    }

    updateJackpot(message){

        let $target = $('#odometer'+message.jackpotAdvId);

        if($target.length === 0){
            console.error('metter not found',message);
            return;
        }

        $target.data('value',message.actualValue);

        // console.log($target);

        $target[0].innerHTML = message.actualValue;

    }

    /**
     * @example jackpot_counter
         mercure.onMessage({
                "messageType": 'jackpot_counter',
                "actualValue": 126,
                "jackpotAdvId": 68,
                "jpName": "defUsdB1",
                "typeName": "BRONZE",
                "topic": "https://kinkinplay.com/jackpot/counter/73",
            })

     * @example jackpot_win
         mercure.onMessage({
            "messageType": 'jackpot_win',
            "actualValue": 126,
            "jackpotAdvId": 68,
            "jpName": "defUsdB1",
            "topic": "https://kinkinplay.com/jackpot/counter/73",
            "typeName": "BRONZE",
            "winValue": 123
        })

     * @param e
     */
    onMessage(message){

        if(Array.isArray(message)){

            console.log('onMessageArray',message);

            $.each(message,(k,item)=>{
                this.onMessage(item)
            });
            return;
        }

        console.log('onMessageItem',message);

        // message.type= 'jackpot';

        if(message.type==='hpl'){
            $mea.ajax.parseResponse(message)
            return ;
        }

        let currentUserName = $('body').data('username');

        switch (message.messageType) {

            case 'JACKPOT_RELOAD':
            case 'BLOCK_CHANGED':
            case 'GAMES_CHANGED':
                $mea.messages.add('System updated. Reloading');
                window.location.reload();
                break;

            case 'BALANCE_CHANGED':
                $('body').trigger('pingRequest');
                break;

            case 'jackpot_counter':
                this.updateJackpot(message);
                break;

            case 'jackpot_win':

                if(message.winnerLogin === currentUserName){
                    this.application.getControllerForElementAndIdentifier(
                        $('#odometerContaier')[0],'odometer'
                    ).youWin(message)
                }

                break;

            default:
                let responseParsed = $mea.ajax.parseResponse(message)
                console.log('unknown message ',message, responseParsed);
                break;

        }



    }




}