import 'timeago'
// import pt from 'timeago/locales/jquery.timeago.pt'
import pl from 'timeago/locales/jquery.timeago.pt'

$.timeago.settings.allowFuture = true;


import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

    static values = {
        date: String
    }

    static actionExecuted = false;

    static registerRelase(){
        $mea.ajax.registerRelase('timeago','.timeago', function (el,selector){
            $(el).data('controller','timeago');
            $(el).data('controller','timeago-date-value');
        });
    }

    connect() {

        moment.suppressDeprecationWarnings = true;

        if (!Controller.actionExecuted) {
            let lang = $('html').attr('lang');
            switch (lang) {
                case 'pl':
                    this.usePl()
                    break;
                case 'en':
                    this.useEn()
                    break;
                case 'pt':
                    this.usePt()
                    break;
            }
            Controller.actionExecuted = true;
        }

       try{

            let m = moment(this.dateValue,'');
            if(m._isValid === false ){
                let elBody = $(this.element).text();
                m = moment(elBody,'');
                if(m._isValid === false ){
                    console.log('timeago not valid',this.dateValue,elBody );
                    return ;
                }
                this.dateValue = m.toISOString();


            }


            let title = moment(this.dateValue,'').format('DD-MM-Y H:mm:s');

            $(this.element).html(
                $.timeago(this.dateValue)
            )
                .show('slideUp')
                .attr('title',title );

            $(this.element).tooltip();


       }catch (e) {
           console.warn('timeago exception',e)
       }


    }

    useEn(){
        // English (Template)
        jQuery.timeago.settings.strings = {
            prefixAgo: null,
            prefixFromNow: null,
            suffixAgo: "ago",
            suffixFromNow: "from now",
            seconds: "less than a minute",
            minute: "about a minute",
            minutes: "%d minutes",
            hour: "about an hour",
            hours: "about %d hours",
            day: "a day",
            days: "%d days",
            month: "about a month",
            months: "%d months",
            year: "about a year",
            years: "%d years",
            wordSeparator: " ",
            numbers: []
        };
    }

    usePt(){
        // Portuguese
        jQuery.timeago.settings.strings = {
            prefixAgo: "há",
            prefixFromNow: "daqui a",
            seconds: "menos de um minuto",
            minute: "cerca de um minuto",
            minutes: "%d minutos",
            hour: "cerca de uma hora",
            hours: "cerca de %d horas",
            day: "um dia",
            days: "%d dias",
            month: "cerca de um mês",
            months: "%d meses",
            year: "cerca de um ano",
            years: "%d anos"
        };
    }

    usePl(){
        // Polish
        function numpf(n, s, t) {
            // s - 2-4, 22-24, 32-34 ...
            // t - 5-21, 25-31, ...
            var n10 = n % 10;
            if ( (n10 > 1) && (n10 < 5) && ( (n > 20) || (n < 10) ) ) {
                return s;
            } else {
                return t;
            }
        }

        jQuery.timeago.settings.strings = {
            prefixAgo: null,
            prefixFromNow: "za",
            suffixAgo: "temu",
            suffixFromNow: null,
            seconds: "mniej niż minutę",
            minute: "minutę",
            minutes: function(value) { return numpf(value, "%d minuty", "%d minut"); },
            hour: "godzinę",
            hours: function(value) { return numpf(value, "%d godziny", "%d godzin"); },
            day: "dzień",
            days: "%d dni",
            month: "miesiąc",
            months: function(value) { return numpf(value, "%d miesiące", "%d miesięcy"); },
            year: "rok",
            years: function(value) { return numpf(value, "%d lata", "%d lat"); }
        };
    }

    disconnect() {
        super.disconnect();
    }


}