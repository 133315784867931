import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

    static values = {
        email: String,
        subject: String,
        body: String,
    }

    // static registerRelase(){
    //     $mea.ajax.registerRelase('timeago','.timeago', function (el,selector){
    //         $(el).data('controller','timeago');
    //         $(el).data('controller','timeago-date-value');
    //     });
    // }

    connect() {

        let email = atob(this.emailValue);
        let emailSub = this.subjectValue;
        let emailBody = this.bodyValue;


        let href =  "mailto:"+email+'?subject='+emailSub+'&body='+emailBody;

        $(this.element)
            .css('cursor','pointer')
            .attr('title',$mea.translator.trans('Click to send email'))
            .click(()=>{
                console.log('href', href);
                 window.top.location.href = href;
            });

        //        console.log('email_widget',this.element, email);

    }

}