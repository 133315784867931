import $ from "jquery";
import './stimulus_bootstrap';

// import './../../../vendor/Mea/CoreBundle/Resources/webpack/MeaSonataWebpackMd4';
// import './../../../vendor/Mea/CoreBundle/Resources/webpack/MeaSonataWebpackMdb.js';

import 'bootstrap';
import * as bootstrap from 'bootstrap';
window.bootstrap = bootstrap;

import bsCustomFileInput from 'bs-custom-file-input';

// window.bsCustomFileInput = bsCustomFileInput;
// import "bootstrap-fileinput";
// import "bootstrap-fileinput/themes/explorer/theme.js";
import anime from "animejs/lib/anime.es";

// import 'mdbootstrap-pro/js/mdb';
// import './../../../vendor/Mea/CoreBundle/Resources/webpack/MeaSonataWebpackMd4';
import './../../../vendor/Mea/CoreBundle/Resources/webpack/MeaSonataWebpackMdb.js';

// window.MdbVersion = 4;
// import 'mdbootstrap-pro/js/mdb';

import "./../../../vendor/Mea/CoreBundle/Resources/public/js2/mea.pack";


const WOW = require('wowjs');
import './relases';

import './../../../vendor/Mea/ContactBundle/Resources/webpack/mea.like';

$mea.like.register();


import {register} from 'swiper/element/bundle';

register();

// import ScrollBooster from 'scrollbooster';

//Cannot read properties of null (reading 'children')  at new ScrollBooster (index.js:57:1)

// new ScrollBooster({
//     viewport: document.querySelector('.mea-scroll'),
//     content: document.querySelector('.mea-scroll-container'),
//     scrollMode: 'transform',
//     direction: 'horizontal',
//     emulateScroll: true,
// });

$.fn.slideToggle = function () {
    // console.log('slideToggle');
    $(this).toggle();
    return this;
};
$.fn.slideDown = function () {
    // console.log('slideDown');
    $(this).show();
    return this;
};
$.fn.slideUp = function (fn) {
    // console.log('slideUp');
    $(this).hide();
    if (typeof fn === 'function') {
        fn();
    }
    return this;
};

var AOS = require('aos');
AOS.init();


$(() => {

    $mea.ajax.registerMeaHplLinks();
    $mea.ajax.registerRelase('href_mea_hpl', 'a:not(.noHpl)',
        function (el, selector) {
            return $mea.ajax.hrefMeaHplInit(el);
        }
    );

    $mea.ajax.activateContent('body');

});

$(document).ready(function () {
    window.wow = new WOW.WOW({
        live: false
    });

    window.wow.init({
        live: true,
        mobile: true,
        // offset: 50,
    });

    $(window).on($mea.dialog.events.afterDialogShow, () => {
        window.wow.sync();
    });
    $(window).on($mea.ajax.events.afterMeaContentChanged, () => {
        window.wow.sync();
    });




    // SIDEBAR ACTIONS https://bootstrapious.com/p/bootstrap-sidebar

    $('#sidebarCollapse').on('click', function () {
        $('#sidebar').toggleClass('active');
        $('.collapse.in').toggleClass('in');
        $('#pageContent').toggleClass('active');
        // $('a[aria-expanded=true]').attr('aria-expanded', 'false');
    });


    // POBRANIE KOLORU Z OBRAZKA I DODANIE GO DO TŁA

        function getDominantColor(imageElement) {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = imageElement.naturalWidth;
        canvas.height = imageElement.naturalHeight;
        ctx.drawImage(imageElement, 0, 0, canvas.width, canvas.height);

        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height).data;
        let r = 0, g = 0, b = 0, count = 0;

        for (let i = 0; i < imageData.length; i += 4 * 100) { // Pomijanie części pikseli dla optymalizacji
        r += imageData[i];     // Red
        g += imageData[i + 1]; // Green
        b += imageData[i + 2]; // Blue
        count++;
    }

        r = Math.floor(r / count);
        g = Math.floor(g / count);
        b = Math.floor(b / count);

        return `rgb(${r}, ${g}, ${b})`;
    }

        function applyDominantColor() {
        const images = document.querySelectorAll('.dominant-color-img');
        images.forEach(img => {
        img.onload = () => {
        const color = getDominantColor(img);
        img.parentElement.style.backgroundColor = color;
    };
    });
    }

        window.addEventListener('load', applyDominantColor);


    // copy text from input
    document.querySelectorAll(".copy-button").forEach(button => {
        button.addEventListener("click", function() {
            let input = this.previousElementSibling;
            input.select();
            input.setSelectionRange(0, 99999);

            if (navigator.clipboard && navigator.clipboard.writeText) {

                navigator.clipboard.writeText(input.value).then(() => {
                    console.log("Text copied:", input.value);
                }).catch(err => {
                    console.error("Clipboard error:", err);
                });
            } else {

                try {
                    document.execCommand("copy");
                    console.log("Text copied (fallback):", input.value);
                } catch (err) {
                    console.error("Fallback copy failed:", err);
                }
            }
        });
    });




});

