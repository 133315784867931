import { Controller } from '@hotwired/stimulus';
var InfiniteScroll = require('infinite-scroll');
var AOS = require('aos');

export default class extends Controller {

    connect() {

        this.initInfiniteScroll();

        this.searchGames();
        this.fitText();
        window.addEventListener("resize", this.fitText.bind(this));

    }


    disconnect() {
        super.disconnect();
        if(window.infinitieLoader)
            window.infinitieLoader.destroy();

        window.removeEventListener("resize", this.fitText.bind(this));
    }

    initInfiniteScroll() {


        let $container = $('.infinite-container');

        if($container.find('.next').length == 0){
            console.log('infinityScroll disabled - next not found');
            return ;
        }

        window.infinitieLoader = new InfiniteScroll('.infinite-container', {
            path: '.next',
            append: '.infinite-item',
            status: '.page-load-status',
            history: false,
            prefill: true,  // Wczytuje elementy, jeśli dostępne miejsce jest większe niż viewport
            scrollThreshold: 800,  // Zaczyna ładować nowe elementy, gdy jesteś 400px od końca

        });



        $container.on( 'load.infiniteScroll', function( event, body, path, response ) {
            console.log(`Loaded: ${path}`);
            console.log('New elements appended');
            // window.wow.sync();
        });


        $container.on('append.infiniteScroll', (event, body, path, response) => {
            console.log(`append.infiniteScroll: ${path}`);
            console.log('New elements appended');
            this.fitText(); // ✅ Teraz this odnosi się do klasy Stimulus
        });


    }

    searchGames(){

        let _this = this;

        let debounceTimeoutId;
        let prevFilter = ''; // Store the previous input value

        let $el = $('#gameSearch');

        // console.log('$el', $el);

        $el.on('input', (e) => {

            let filter = $el.val();

            // Check if the current input value is the same as the previous one
            if(filter === prevFilter) return; // If so, exit the event handler early

            prevFilter = filter; // Update the previous input value

            clearTimeout(debounceTimeoutId);
            debounceTimeoutId = setTimeout(() => {

                let target;
                if($('#centerContent').length===1){
                    target = 'centerContent';
                }

                $mea.ajax.load('/games?urltarget=' + target + '&query=' + filter);

            }, 500);

        });
    }

    fitText() {
        document.querySelectorAll(".text-fit").forEach(el => {
            let parent = el.parentElement;
            if (!parent) return;

            let scaleFactor = Math.min(
                parent.clientWidth / el.scrollWidth,
                parent.clientHeight / el.scrollHeight
            );


            el.style.transform = `scale(${scaleFactor})`;
            el.style.transformOrigin = "center";
        });
    }

}