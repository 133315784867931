import { startStimulusApp } from '@symfony/stimulus-bridge';

export const app = startStimulusApp(require.context(
    '@symfony/stimulus-bridge/lazy-controller-loader!./controllers',
    true,
    /\.(j|t)sx?$/
));

import TimeAgo from '@mea/core/webpack/timeago/timeago_controller';
import EmailWidget from '@mea/core/webpack/email-widget/email_widget_controller';

import Mea_stimulus from '@mea/core/webpack/stimulus/mea_stimulus';
app.register("timeago", TimeAgo)
app.register("email-widget", EmailWidget)

// Mea_stimulus.registerAll(app)

