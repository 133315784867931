import { Controller } from '@hotwired/stimulus';
import $ from "jquery";

export default class extends Controller {
    connect() {
        this.initTabsToDropdown();
    }

    initTabsToDropdown() {
        const $tabsToDropdown = $(this.element);
        const $navWrapper = $tabsToDropdown.find(".nav-wrapper");
        const $navPills = $tabsToDropdown.find(".nav-pills");
        const firstTextLink = $navPills.find("li:first-child a").text();
        const $items = $navPills.find("li");

        const dropdownMarkup = `
      <div class="dropdown d-lg-none">
        <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          ${firstTextLink}
        </button>
        <div class="dropdown-menu"> 
          ${this.generateDropdownLinksMarkup($items)}
        </div>
      </div>
    `;

        $navWrapper.prepend(dropdownMarkup);

        this.addEventListeners($tabsToDropdown);
    }

    generateDropdownLinksMarkup(items) {
        let markup = "";
        items.each(function () {
            const textLink = $(this).find("a").text();
            markup += `<a class="dropdown-item btn" href="#">${textLink}</a>`;
        });
        return markup;
    }

    addEventListeners($tabsToDropdown) {
        const $dropdown = $tabsToDropdown.find(".dropdown");
        const $dropdownLinks = $tabsToDropdown.find(".dropdown-menu a");
        const $pills = $tabsToDropdown.find('a[data-bs-toggle="pill"]');

        $dropdown.on("show.bs.dropdown", (e) => this.showDropdownHandler(e));
        $dropdownLinks.on("click", (e) => this.clickHandler(e, $tabsToDropdown));
        $pills.on("shown.bs.tab", (e) => this.shownTabsHandler(e, $tabsToDropdown));
    }

    showDropdownHandler(event) {
        const $dropdownMenuLinks = $(event.target).find(".dropdown-menu a");
        const $dropdownToggle = $(event.target).find(".dropdown-toggle");
        const dropdownToggleText = $dropdownToggle.text().trim();
        $dropdownMenuLinks.each(function () {
            const $this = $(this);
            $this.toggleClass("d-none", $this.text() === dropdownToggleText);
        });
    }

    clickHandler(event, $tabsToDropdown) {
        event.preventDefault();
        const $this = $(event.target);
        const index = $this.index();
        const text = $this.text();

        $this.closest(".dropdown").find(".dropdown-toggle").text(text);
        $tabsToDropdown.find(`.nav-pills li:eq(${index}) a`).tab("show");
    }

    shownTabsHandler(event, $tabsToDropdown) {
        const index = $(event.target).parent().index();
        const $targetDropdownLink = $tabsToDropdown.find(".dropdown-menu a").eq(index);
        const targetDropdownLinkText = $targetDropdownLink.text();
        $tabsToDropdown.find(".dropdown-toggle").text(targetDropdownLinkText);
    }
}
