import { Controller } from '@hotwired/stimulus';
import { NativeEventSource, EventSourcePolyfill } from 'event-source-polyfill';
import $ from "jquery";

export default class extends Controller {
    static values = {
        host: String ,
        token: String ,
        path: String ,
        topics: Array
    };

    connect() {

        if(this.pathValue){
            this.lockNextTabInit();
        }else{
            console.log('Admin: lock other tab disabled path empty ',this.pathValue);
        }
        this.closeRightPanel();

    }


    closeRightPanel() {

        const btnHideRightBlock = document.getElementById('btnHideRightBlock');
        const gameInfoRightBlock = document.getElementById('gameInfoRightBlock');

        if(btnHideRightBlock)
        btnHideRightBlock.addEventListener('click', function () {
            gameInfoRightBlock.classList.add('d-none');
        });

    }

    lockNextTabInit(){
        function lockNexTab()
        {
            if ($('body').data('username')){
                if (localStorage.getItem('web_browser') == null) {
                    // new tab
                    localStorage.setItem('web_browser', 'true');
                    window.addEventListener('unload', function() {
                        localStorage.removeItem('web_browser');
                    })
                    return false;
                }else {
                    return true;
                }

            }else {
                localStorage.removeItem('web_browser');
                return false;
            }

        }

        let lockNeed = lockNexTab();
        // console.log('lock app ',lockNeed);
        if (lockNeed && $('[data-islock="1"]').length === 0) {
            console.log('locking app ',lockNeed);
            $("body").load(this.pathValue, function(responseTxt, statusTxt, xhr){
                // if(statusTxt === "success")
                //     alert("External content loaded successfully!");
                if(statusTxt === "error")
                    console.error("Error: " + xhr.status + ": " + xhr.statusText);
            });
            // window.location.href = this.pathValue;
        }else{
            // console.log('locking app not necesary');
        }

    }



}